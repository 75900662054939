<template>
    <div :class="['visual-page-loading', {'visual-page-loading__theme-white': theme === 'white'}]">
        <div class="visual-page-loading__svg">
            <svg width="90" height="89" viewBox="0 0 90 89" fill="#1A1919" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V17.9575H27.9091V28.5137H19.668V71.1442H5.79125V28.5137H0V88.9286H90V71.1442H77.8746L70.725 46.7272V71.1442H59.1228V17.9575H70.725L78.5039 41.7089V17.9575H90V0H0ZM30.4049 17.7857H53.7959V28.3757H44.4328V38.4098H53.1963V48.4767H44.4328V60.1141H54.7292V71.1428H30.4049V17.7857Z"></path>
            </svg>
        </div>
    </div>
</template>

<script>
export default {props: ['theme']};
</script>

<style lang="scss">
   @import "./PageLoading";
</style>
