<template>
    <div class="visual-build-plane" v-if="data">
        <div class="visual-build-plane__caption">План Комплекса</div>

        <div :class="['visual-build-plane__canvas', {'no-events': noLink}]">
            <svg :viewBox="data.viewBox"
                 class="visual-build-plane__svg"
                 preserveAspectRatio="xMidYMid slice"
                 xmlns="http://www.w3.org/2000/svg">

                <g v-if="!noLink">
                    <g v-for="section in sections" :key="section.id">
                        <path v-if="section.disabled || !section.redirect" :d="section.coords" :class="['visual-build-plane__path', {'is-active': section.active}]"/>

                        <router-link v-else :to="section.redirect || ''" @click.native="$emit('change')">
                            <path :d="section.coords" :class="['visual-build-plane__path', {'is-active': section.active}]"/>
                        </router-link>
                    </g>
                </g>

                <g v-else>
                    <path v-for="section in sections"
                          :key="section.id"
                          :d="section.coords"
                          :class="['visual-build-plane__path', {'is-active': section.active}]"/>
                </g>

                <image x="0" y="0" width="100%" height="100%" preserveAspectRatio="none" :xlink:href="data.render" class="visual-build-plane__image" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {props: ['noLink', 'data', 'sections']};
</script>

<style lang="scss" scoped>
    @import "./BuildPlane";
</style>
