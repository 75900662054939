<template>
    <transition name="fade">
        <div v-show="isOpen" class="visual-chess" v-hammer:swipe.right="onSwipeLeft">
            <transition name="fade">
                <div class="visual-chess__overlay" v-show="isOpen" @click="isOpen = false"></div>
            </transition>

            <transition name="right">
                <div class="visual-chess__content" v-if="this.section.idComplex == 35" v-show="isOpen" data-complex-id="35">
                    <div class="visual-chess__close" @click="isOpen = false"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.5652 1.43478L1.41503 20.585" stroke="#1D1D1B" stroke-width="2"/><path d="M20.5652 20.5652L1.41503 1.41503" stroke="#1D1D1B" stroke-width="2"/></svg></div>
                    <div class="visual-chess__main">
                        <div class="visual-chess__main-top">
                            <div class="visual-chess__floor">
                                <div class="visual-chess__floor-caption">Этаж</div>
                            </div>

                            <div class="visual-chess__section">
                                <div class="visual-chess__info-section-legend">
                                    <div class="visual-chess__info-section-legend-row">
                                        <span class="visual-chess__info-section-legend-color" style="background-color: rgb(148, 194, 61); opacity: 0.5;"></span>
                                        <span class="visual-chess__info-section-legend-title">В продаже</span>
                                    </div>
                                    <div class="visual-chess__info-section-legend-row">
                                        <span class="visual-chess__info-section-legend-color" style="background-color: #ffff00; opacity: 0.5;"></span>
                                        <span class="visual-chess__info-section-legend-title">Бронь</span>
                                    </div>

                                    <div class="visual-chess__info-section-legend-row">
                                        <span class="visual-chess__info-section-legend-color" style="background-color: rgb(255, 255, 255);"></span>
                                        <span class="visual-chess__info-section-legend-title">Продано</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <perfect-scrollbar class="visual-chess__main-middle" ref="content-flats">
                            <div class="visual-chess__floor visual-chess__floor-pt-20">
                                <ul class="visual-chess__floor-list">
                                    <li v-for="floor in getRangeFloors"
                                        :key="floor">
                                        {{ floor }}
                                    </li>
                                </ul>
                            </div>

                            <div v-if="getSections" class="visual-chess__sections">
                                <div v-for="section in getSections" class="visual-chess__section" :key="section.id">
                                    <div class="visual-chess__section-name">{{section.name}}</div>
                                    <div class="visual-chess__section-left">
                                        <div class="visual-chess__divider"></div>
                                    </div>

                                    <div :class="['visual-chess__section-center', {'visual-chess__section-center_text-left': !prevSection}]">
                                        <div v-if="section.floors" class="visual-chess__flats">
                                            <div v-for="floor in section.floors"
                                                    :key="floor.id"
                                                    :class="['visual-chess__flats-line', {'is-disabled': floor.disabled}]">

                                                <a v-for="flat in floor.flats"
                                                    :key="flat.id"
                                                    :href="flat.href"
                                                    :class="[
                                                        'visual-chess__flat',
                                                        {'visual-chess__flat_is_sold': flat.sold},
                                                        {'visual-chess__flat_action': (((flat.oldprice && !flat.sold) && testAB !== 'b-test') || (flat.stock && !flat.sold))}
                                                    ]"
                                                    @mouseenter="mouseEnterFlat(flat)"
                                                    @mouseleave="mouseLeaveFlat">
                                                    <span class="visual-chess__flat-bg" :style="{
                                                        backgroundColor: flat.fill,
                                                        opacity: flat.fillOpacity}"/>

                                                    <span :class="[
                                                        'visual-chess__flat-num',
                                                        {'visual-chess__flat-num-sold': flat.sold && !flat.reserved}
                                                    ]">{{ flat.slug }}</span>

                                                    <div class="visual-chess__flat-actions">
                                                        <div
                                                            v-for="sale in flat.sales"
                                                            :key="sale.id"
                                                            class="visual-chess__flat-sale" :style="{
                                                                backgroundColor: sale.bgColor,
                                                                color: sale.textColor
                                                            }"></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="visual-chess__section-right">
                                        <div class="visual-chess__divider"></div>
                                    </div>
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>

                    <div class="visual-chess__info">
                        <div class="visual-chess__info-flat">
                            <transition name="fade"><FlatInfo v-if="activeFlat" :flat="activeFlat" :testAB="testAB"/></transition>
                        </div>

                        <div class="visual-chess__info-section">
                            <div class="visual-chess__info-section-legend">
                                <div class="visual-chess__info-section-legend-row">
                                    <span class="visual-chess__info-section-legend-color" style="background-color: rgb(148, 194, 61); opacity: 0.5;"></span>
                                    <span class="visual-chess__info-section-legend-title">В продаже</span>
                                </div>
                                <div class="visual-chess__info-section-legend-row">
                                    <span class="visual-chess__info-section-legend-color" style="background-color: #ffff00; opacity: 0.5;"></span>
                                    <span class="visual-chess__info-section-legend-title">Бронь</span>
                                </div>

                                <div class="visual-chess__info-section-legend-row">
                                    <span class="visual-chess__info-section-legend-color" style="background-color: rgb(255, 255, 255);"></span>
                                    <span class="visual-chess__info-section-legend-title">Продано</span>
                                </div>
                            </div>

                            <BuildPlane :noLink="false" :data="buildMap" :sections="sections" @change="$emit('change')" />
                        </div>

                        <a v-if="getSearchUrl !== false" :href="getSearchUrl" class="visual-chess__search-link">
                            <span class="visual-chess__search-link__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
                                    <g>
                                        <path
                                            d="M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474    c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323    c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848    S326.847,409.323,225.474,409.323z"
                                        />
                                    </g>
                                    <g>
                                        <path
                                            d="M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328    c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z"
                                        />
                                    </g>
                                </svg>

                            </span>
                            <span>Поиск по параметрам</span>
                        </a>
                        <a v-if="getLinkParking" :href="getLinkParking" class="visual-chess__info-parking">
                            <div class="visual-chess__info-parking-wrapper">
                                <div class="visual-chess__info-parking-icon">
                                    <svg enable-background="new 0 0 394.47 394.47" height="512" viewBox="0 0 394.47 394.47" width="512" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m352.206 0h-309.941c-23.306 0-42.265 18.959-42.265 42.265v309.941c0 23.306 18.959 42.265 42.265 42.265h309.941c23.306 0 42.265-18.959 42.265-42.265v-309.941c-.001-23.306-18.959-42.265-42.265-42.265zm14.088 352.206c0 7.773-6.315 14.088-14.088 14.088h-309.941c-7.773 0-14.088-6.315-14.088-14.088v-309.941c0-7.773 6.315-14.088 14.088-14.088h309.941c7.773 0 14.088 6.315 14.088 14.088z"/>
                                        <path d="m211.323 84.529h-84.529c-7.787 0-14.088 6.301-14.088 14.088v211.323h28.176v-84.529h70.441c38.839 0 70.441-31.602 70.441-70.441s-31.602-70.441-70.441-70.441zm0 112.706h-70.441v-84.529h70.441c23.306 0 42.265 18.959 42.265 42.265s-18.958 42.264-42.265 42.264z"/>
                                    </svg>
                                </div>
                                <div class="visual-chess__info-parking-name">Паркинг</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="visual-chess__content" v-else v-show="isOpen">
                    <div class="visual-chess__close" @click="isOpen = false"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.5652 1.43478L1.41503 20.585" stroke="#1D1D1B" stroke-width="2"/><path d="M20.5652 20.5652L1.41503 1.41503" stroke="#1D1D1B" stroke-width="2"/></svg></div>
                    <div class="visual-chess__main">
                        <div class="visual-chess__main-top">
                            <div class="visual-chess__floor">
                                <div class="visual-chess__floor-caption">Этаж</div>
                            </div>

                            <div class="visual-chess__section">
                                <div class="visual-chess__section-left" v-if="prevSection">
                                    <router-link class="visual-chess__section-prev" :to="prevSection.redirect || ''" @click.native="$emit('change')">
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.88174 3.481L12 3.481L12 4.519L1.88174 4.519L4.48328 7.26602L3.78817 8L4.76995e-08 4L3.78817 9.79251e-08L4.48328 0.733979L1.88174 3.481Z" fill="#0483A0"/></svg>
                                        {{ prevSection.planName }}
                                    </router-link>
                                </div>

                                <div :class="['visual-chess__section-center', {'visual-chess__section-center_text-left': !prevSection}]">
                                    <div class="visual-chess__section-current">{{ section.planName }}</div>

                                    <div v-if="flatsFirstFloor" class="visual-chess__flats">
                                        <div :class="['visual-chess__flats-line']">
                                            <div v-for="flat in flatsFirstFloor"
                                               :key="flat.id"
                                               :class="['visual-chess__flat', 'visual-chess__flat_for_calc_width']">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="visual-chess__section-right" v-if="nextSection">
                                    <router-link class="visual-chess__section-next" :to="nextSection.redirect || ''" @click.native="$emit('change')">
                                        {{ nextSection.planName }}
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.1183 4.519L0 4.519L0 3.481L10.1183 3.481L7.51672 0.733978L8.21183 0L12 4L8.21183 8L7.51672 7.26602L10.1183 4.519Z" fill="#0483A0"/></svg>
                                    </router-link>
                                </div>

                                <div class="visual-chess__info-section-legend">
                                    <div class="visual-chess__info-section-legend-row">
                                        <span class="visual-chess__info-section-legend-color" style="background-color: rgb(148, 194, 61); opacity: 0.5;"></span>
                                        <span class="visual-chess__info-section-legend-title">В продаже</span>
                                    </div>
                                    <div class="visual-chess__info-section-legend-row">
                                        <span class="visual-chess__info-section-legend-color" style="background-color: #ffff00; opacity: 0.5;"></span>
                                        <span class="visual-chess__info-section-legend-title">Бронь</span>
                                    </div>

                                    <div class="visual-chess__info-section-legend-row">
                                        <span class="visual-chess__info-section-legend-color" style="background-color: rgb(255, 255, 255);"></span>
                                        <span class="visual-chess__info-section-legend-title">Продано</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <perfect-scrollbar class="visual-chess__main-middle" ref="content-flats">
                            <div class="visual-chess__floor">
                                <ul class="visual-chess__floor-list">
                                    <li v-for="floor in floorsPretty"
                                        :key="floor.id"
                                        :class="[{'is-active': floor.active}]">
                                        {{ floor.text }}
                                    </li>
                                </ul>
                            </div>

                            <div class="visual-chess__section">
                                <div class="visual-chess__section-left" v-if="prevSection">
                                    <div class="visual-chess__prev-border"></div>
                                </div>

                                <div :class="['visual-chess__section-center', {'visual-chess__section-center_text-left': !prevSection}]">
                                    <div v-if="floors" class="visual-chess__flats">
                                        <div v-for="floor in floors"
                                             :key="floor.id"
                                             :class="['visual-chess__flats-line', {'is-disabled': floor.disabled}]">

                                            <a v-for="flat in floor.flats"
                                               :key="flat.id"
                                               :href="flat.href"
                                               :class="[
                                                   'visual-chess__flat',
                                                   {'visual-chess__flat_is_sold': flat.sold},
                                                   {'visual-chess__flat_action': (((flat.oldprice && !flat.sold) && testAB !== 'b-test') || (flat.stock && !flat.sold))}
                                               ]"
                                               @mouseenter="mouseEnterFlat(flat)"
                                               @mouseleave="mouseLeaveFlat">
                                                <span class="visual-chess__flat-bg" :style="{
                                                    backgroundColor: flat.fill,
                                                    opacity: flat.fillOpacity}"/>

                                                <span :class="[
                                                   'visual-chess__flat-num',
                                                   {'visual-chess__flat-num-sold': flat.sold && !flat.reserved}
                                               ]">{{ flat.slug }}</span>

                                               <div class="visual-chess__flat-actions">
                                                    <div
                                                        v-for="sale in flat.sales"
                                                        :key="sale.id"
                                                        class="visual-chess__flat-sale" :style="{
                                                            backgroundColor: sale.bgColor,
                                                            color: sale.textColor
                                                        }"></div>
                                               </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="visual-chess__section-right" v-if="nextSection">
                                    <div class="visual-chess__next-border"></div>
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>

                    <div class="visual-chess__info">
                        <div class="visual-chess__info-flat">
                            <transition name="fade"><FlatInfo v-if="activeFlat" :flat="activeFlat" :testAB="testAB"/></transition>
                        </div>

                        <div class="visual-chess__info-section">
                            <div class="visual-chess__info-section-legend">
                                <div class="visual-chess__info-section-legend-row">
                                    <span class="visual-chess__info-section-legend-color" style="background-color: rgb(148, 194, 61); opacity: 0.5;"></span>
                                    <span class="visual-chess__info-section-legend-title">В продаже</span>
                                </div>
                                <div class="visual-chess__info-section-legend-row">
                                    <span class="visual-chess__info-section-legend-color" style="background-color: #ffff00; opacity: 0.5;"></span>
                                    <span class="visual-chess__info-section-legend-title">Бронь</span>
                                </div>

                                <div class="visual-chess__info-section-legend-row">
                                    <span class="visual-chess__info-section-legend-color" style="background-color: rgb(255, 255, 255);"></span>
                                    <span class="visual-chess__info-section-legend-title">Продано</span>
                                </div>
                            </div>

                            <BuildPlane :noLink="false" :data="buildMap" :sections="sections" @change="$emit('change')" />
                        </div>

                        <a v-if="getSearchUrl !== false" :href="getSearchUrl" class="visual-chess__search-link">
                            <span class="visual-chess__search-link__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
                                    <g>
                                        <path
                                            d="M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474    c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323    c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848    S326.847,409.323,225.474,409.323z"
                                        />
                                    </g>
                                    <g>
                                        <path
                                            d="M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328    c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z"
                                        />
                                    </g>
                                </svg>

                            </span>
                            <span>Поиск по параметрам</span>
                        </a>
                        <a v-if="getLinkParking" :href="getLinkParking" class="visual-chess__info-parking">
                            <div class="visual-chess__info-parking-wrapper">
                                <div class="visual-chess__info-parking-icon">
                                    <svg enable-background="new 0 0 394.47 394.47" height="512" viewBox="0 0 394.47 394.47" width="512" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m352.206 0h-309.941c-23.306 0-42.265 18.959-42.265 42.265v309.941c0 23.306 18.959 42.265 42.265 42.265h309.941c23.306 0 42.265-18.959 42.265-42.265v-309.941c-.001-23.306-18.959-42.265-42.265-42.265zm14.088 352.206c0 7.773-6.315 14.088-14.088 14.088h-309.941c-7.773 0-14.088-6.315-14.088-14.088v-309.941c0-7.773 6.315-14.088 14.088-14.088h309.941c7.773 0 14.088 6.315 14.088 14.088z"/>
                                        <path d="m211.323 84.529h-84.529c-7.787 0-14.088 6.301-14.088 14.088v211.323h28.176v-84.529h70.441c38.839 0 70.441-31.602 70.441-70.441s-31.602-70.441-70.441-70.441zm0 112.706h-70.441v-84.529h70.441c23.306 0 42.265 18.959 42.265 42.265s-18.958 42.264-42.265 42.264z"/>
                                    </svg>
                                </div>
                                <div class="visual-chess__info-parking-name">Паркинг</div>
                            </div>
                        </a>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import FlatInfo from '../FlatInfo/FlatInfo';
import BuildPlane from '../BuildPlane/BuildPlane';

export default {
    components: {
        FlatInfo,
        BuildPlane
    },
    props: ['sections', 'floors', 'buildMap', 'linkParking', 'searchUrl'],
    data : () => {
        return {
            isOpen    : false,
            activeFlat: {},
            testAB    : false
        };
    },
    computed: {
        floorsPretty() {
            return this.floors.sort((a, b) => {
                return b.text - a.text;
            });
        },
        section() {
            // eslint-disable-next-line multiline-ternary
            let section = null;

            section = this.sections ? this.sections.find((section) => {
                return section.active;
            }) : null;

            if ( section ) {
                section.planName = section.name;
                if ( section.planName.indexOf('очередь') !== -1 ) {
                    section.planName = section.planName.replace('очередь', 'оч.');
                }
            }

            return section;
        },
        prevSection() {
            if (!this.sections) {
                return null;
            }

            let prevSection = null;

            this.sections.forEach((section, key) => {
                if (section.active && this.sections[key - 1]) {
                    prevSection = this.sections[key - 1];
                }
            });

            if ( prevSection ) {
                prevSection.planName = prevSection.name;
                if ( prevSection.planName.indexOf('очередь') !== -1 ) {
                    prevSection.planName = prevSection.planName.replace('очередь', 'оч.');
                }
            }

            return prevSection;
        },
        nextSection() {
            if (!this.sections) {
                return null;
            }

            let nextSection = null;

            this.sections.forEach((section, key) => {
                if (section.active && this.sections[key + 1]) {
                    nextSection = this.sections[key + 1];
                }
            });

            if ( nextSection ) {
                nextSection.planName = nextSection.name;
                if ( nextSection.planName.indexOf('очередь') !== -1 ) {
                    nextSection.planName = nextSection.planName.replace('очередь', 'оч.');
                }
            }

            return nextSection;
        },
        flatsFirstFloor() {
            let mostLength = this.floors[0].flats;

            this.floors.forEach((floor) => {
                if (floor.flats.length > mostLength.length) {
                    mostLength = floor.flats;
                }
            });

            return mostLength;
        },
        getRangeFloors() {
            const rangeFloors = this.sections.reduce((acc, {floors, id}) => {
                const floorMaxInSection = Math.max(...Object.values(floors).map(({text}) => parseInt(text)));
                const floorMinInSection = Math.min(...Object.values(floors).map(({text}) => parseInt(text)));

                if (floorMinInSection > acc.min) acc.min = floorMinInSection;
                if (floorMaxInSection > acc.max) acc.max = floorMaxInSection;

                return acc;
            }, {min: 0, max: 0});

            const prettyFloors = []

            for (let index = rangeFloors.min; index <= rangeFloors.max; index++) {
                prettyFloors.push(index);
            }

            return prettyFloors.reverse();
        },
        getLinkParking () {
            return (this.linkParking.length > 0) ? this.linkParking : false;
        },
        getSearchUrl() {
            return (this.searchUrl !== false && this.searchUrl.length > 0) ? this.searchUrl : false;
        },
        getSections() {
            if (!this.sections) {
                return null;
            }

            return Object.values(Object.values(this.sections).map((item, idx) => {
                item.idx = idx;

                item.floors = item.floors.sort(function(item, nextItem) {
                    return parseInt(nextItem.text) - parseInt(item.text);
                });

                return item;
            }));
        }
    },
    methods: {
        toOpen() {
            this.isOpen = true;
        },
        onSwipeLeft() {
            this.isOpen = false;
        },
        setActiveSection(current) {
            this.sections.forEach((section) => {
                section.active = section === current;
            });
        },
        mouseEnterFlat(flat) {
            clearTimeout(this.timerHover);

            this.activeFlat = flat;
        },
        mouseLeaveFlat() {
            this.timerHover = setTimeout(() => {
                this.activeFlat = null;
            }, 300);
        },
        // возвращает куки с указанным name,
        // или undefined, если ничего не найдено
        getCookie(name) {
            // eslint-disable-next-line max-len,no-useless-escape
            const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));

            // eslint-disable-next-line no-undefined
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }
    },
    beforeMount() {
        this.testAB = this.getCookie('AB_TEST_CLASS');
    }
};
</script>

<style lang="scss">
    @import "./Chess";
    @import "./scroll";
</style>
