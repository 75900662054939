<template>
    <div class="visual-floors">
        <div class="visual-floors__title">Выбор этажа</div>

        <div class="visual-floors__wrapper">
            <span v-if="arrowVisible"
                  :class="['visual-floors__up', {'is-disable': !upMove}]"
                  @click="toUp">
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6.84424L6 1.85701L11 6.84424" stroke="black" stroke-width="1.5"/><path d="M5.25 1.85693L5.25 15.3225H6.75L6.75 1.85693H5.25Z" fill="black"/></svg>
            </span>

            <ul class="visual-floors__list" ref="list" @scroll="checkScrollArrow">
                <li v-for="floor in floors.slice().reverse()"
                    :key="floor.id"
                    :class="['visual-floors__item', {'is-active': floor.active}, {'is-disabled': floor.disabled}]"
                    ref="floor" @click="activeFloor(floor)">
                    <router-link :to="{name: 'floor', params: {...$route.params, idFloor: floor.id}}">
                        {{ floor.text }} <i v-if="floor.center">-</i>
                    </router-link>
                </li>
            </ul>

            <span v-if="arrowVisible"
                  :class="['visual-floors__down', {'is-disable': !downMove}]"
                  @click="toDown">
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 8.01416L6 13.0014L1 8.01416" stroke="black" stroke-width="1.5"/><path d="M5.25 0.034668L5.25 13.5002H6.75L6.75 0.034668L5.25 0.034668Z" fill="black"/></svg>
            </span>
        </div>

    <MultiSelect
        v-if="floorSelect"
        key="floor"
        name="floor"
        :single="true"
        :redirect="true"
        :disabled="false"
        :placeholder="'hello'"
        :options="floorSelect"
        @redirect="$emit('redirect')"/>
    </div>
</template>

<script>
import MultiSelect from "../forms/MultiSelect/MultiSelect";
export default {
    components: {
        MultiSelect,
    },
    props: ['floors'],
    data : () => {
        let windowWidth = window.innerWidth && document.documentElement.clientWidth
        ? Math.min(window.innerWidth, document.documentElement.clientWidth)
        : window.innerWidth ||
              document.documentElement.clientWidth ||
              document.getElementsByTagName("body")[0].clientWidth;

        let windowHeight = window.innerHeight && document.documentElement.clientHeight
        ? Math.min(window.innerHeight, document.documentElement.clientHeight)
        : window.innerHeight ||
              document.documentElement.clientHeight ||
              document.getElementsByTagName("body")[0].clientHeight;

        return {
            // arrowVisible: true,
            arrowVisible: ( windowWidth <= 1280 || windowHeight <= 780 ),
            upMove      : true,
            downMove    : true,
            duration    : 300,
            heightWindow: window.innerHeight
        };
    },
    watch: {
        /*
        floors() {
            setTimeout(() => {
                this.getMaxScrollTop();
                this.setScrollTop();
                this.checkScrollArrow();
            }, 0);
        }
        */
    },
    computed: {
        stepPx() {
            return (28 + 15) * (this.heightWindow >= 690 ? 4 : 2);
        },
        floorSelect () {
            if ( !this.floors ) return false;

            return this.floors.map((floor) => {
                return {
                    checked : floor.active,
                    disabled: floor.disabled,
                    text    : floor.text,
                    value   : floor.redirect || ''
                };
            });
        },
    },
    methods: {
        toUp() {
            $(this.$refs.list).animate({scrollTop: this.$refs.list.scrollTop - this.stepPx}, this.duration);
        },
        toDown() {
            $(this.$refs.list).animate({scrollTop: this.$refs.list.scrollTop + this.stepPx}, this.duration);
        },
        getMaxScrollTop() {
            this.$refs.list.scrollTop = this.$refs.list.scrollHeight;

            this.maxScrollTop = this.$refs.list.scrollTop;
        },
        setScrollTop() {
            let top = 0;
            const margin = 15;
            const offset = 38 * 2;

            this.$refs.floor.find((floor) => {
                if (!floor.classList.contains('is-active')) {
                    top = top + (floor.offsetHeight + margin);
                }

                return floor.classList.contains('is-active');
            });

            this.$refs.list.scrollTop = top - margin - offset;
        },
        checkScrollArrow() {
            this.upMove = this.$refs.list.scrollTop > 0;
            this.downMove = this.$refs.list.scrollTop < this.maxScrollTop;
            this.arrowVisible = this.$refs.list.scrollHeight > this.$refs.list.offsetHeight;
        },

        activeFloor(current) {
            this.floors.forEach((floor) => {
                floor.active = floor === current;
            });
        },
        /**
         * Берет ближние эл массива
         */
        getNearby(count, fromKey, array) {
            const centerArray = [];
            let mCount = count - 1;

            if (!array[fromKey]) {
                return;
            }

            centerArray.push(array[fromKey]);

            for (let i = 1; i <= count; i++) {
                if (array[fromKey - i] && mCount) {
                    centerArray.push(array[fromKey - i]);

                    mCount--;
                }

                if (array[fromKey + i] && mCount) {
                    centerArray.push(array[fromKey + i]);

                    mCount--;
                }
            }

            return array.filter((val) => {
                return centerArray.includes(val);
            });
        },

        setHeightWindow() {
            this.heightWindow = window.innerHeight;
        }
    },
    mounted() {
        /*
        this.getMaxScrollTop();
        this.setScrollTop();
        this.checkScrollArrow();
        */

        window.addEventListener('resize', this.setHeightWindow);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setHeightWindow);
    }
};
</script>

<style lang="scss" scoped>
    @import "./Floors";
</style>
