<template>
    <div class="visual-flat">
        <div v-if="flat.img" class="visual-flat__img">
            <img :src="flat.img" :alt="flat.title">
        </div>

        <div class="visual-flat__title">{{ flat.title }}</div>
        <div v-if="flat.area" class="visual-flat__area">Общая площадь {{ flat.area }}</div>
        <div v-if="flat.utp" class="visual-flat__utp">
            <span v-for="key in (flat.utp.length > 2) ? 2 : flat.utp.length" :key="key">{{ flat.utp[key-1] }}</span>
            <span v-if="flat.utp.length > 2" class="visual-flat__utp-more"><span></span><span></span><span></span></span>
        </div>

        <div v-if="flat.reserved" class="visual-flat__price">Забронирована</div>
        <div v-else-if="flat.price" class="visual-flat__price">
            {{(flat.oldprice && testAB === 'b-test') ? flat.oldprice : flat.price }} <span class="ruble">a</span>
            <div v-if="flat.discount && testAB !== 'a-test'" class="visual-flat__discount-icon">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.971 7.605C9.971 8.18567 9.78033 8.632 9.399 8.944C9.02633 9.256 8.56267 9.412 8.008 9.412C7.45333 9.412 6.98533 9.256 6.604 8.944C6.23133 8.632 6.045 8.18567 6.045 7.605V6.37C6.045 5.78933 6.23133 5.343 6.604 5.031C6.98533 4.719 7.45333 4.563 8.008 4.563C8.56267 4.563 9.02633 4.719 9.399 5.031C9.78033 5.343 9.971 5.78933 9.971 6.37V7.605ZM3.926 3.042C3.926 3.62267 3.73533 4.069 3.354 4.381C2.98133 4.693 2.51767 4.849 1.963 4.849C1.40833 4.849 0.940333 4.693 0.559 4.381C0.186333 4.069 0 3.62267 0 3.042V1.807C0 1.22633 0.186333 0.78 0.559 0.468C0.940333 0.156 1.40833 0 1.963 0C2.51767 0 2.98133 0.156 3.354 0.468C3.73533 0.78 3.926 1.22633 3.926 1.807V3.042ZM8.008 0.0780005L3.601 9.334H1.963L6.37 0.0780005H8.008ZM2.418 2.99V1.859C2.418 1.521 2.26633 1.352 1.963 1.352C1.65967 1.352 1.508 1.521 1.508 1.859V2.99C1.508 3.328 1.65967 3.497 1.963 3.497C2.26633 3.497 2.418 3.328 2.418 2.99ZM8.463 7.553V6.422C8.463 6.084 8.31133 5.915 8.008 5.915C7.70467 5.915 7.553 6.084 7.553 6.422V7.553C7.553 7.891 7.70467 8.06 8.008 8.06C8.31133 8.06 8.463 7.891 8.463 7.553Z" fill="#1D1D1B"/></svg>
            </div>
            <div v-if="flat.discount && testAB !== 'a-test'" class="visual-flat__discount-text">
                Скидка при 100% оплате <br>
                Подробности уточняйте в отделе продаж.
            </div>

            <div v-if="flat.stock && flat.stock != ''" class="visual-flat__discount-icon">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.971 7.605C9.971 8.18567 9.78033 8.632 9.399 8.944C9.02633 9.256 8.56267 9.412 8.008 9.412C7.45333 9.412 6.98533 9.256 6.604 8.944C6.23133 8.632 6.045 8.18567 6.045 7.605V6.37C6.045 5.78933 6.23133 5.343 6.604 5.031C6.98533 4.719 7.45333 4.563 8.008 4.563C8.56267 4.563 9.02633 4.719 9.399 5.031C9.78033 5.343 9.971 5.78933 9.971 6.37V7.605ZM3.926 3.042C3.926 3.62267 3.73533 4.069 3.354 4.381C2.98133 4.693 2.51767 4.849 1.963 4.849C1.40833 4.849 0.940333 4.693 0.559 4.381C0.186333 4.069 0 3.62267 0 3.042V1.807C0 1.22633 0.186333 0.78 0.559 0.468C0.940333 0.156 1.40833 0 1.963 0C2.51767 0 2.98133 0.156 3.354 0.468C3.73533 0.78 3.926 1.22633 3.926 1.807V3.042ZM8.008 0.0780005L3.601 9.334H1.963L6.37 0.0780005H8.008ZM2.418 2.99V1.859C2.418 1.521 2.26633 1.352 1.963 1.352C1.65967 1.352 1.508 1.521 1.508 1.859V2.99C1.508 3.328 1.65967 3.497 1.963 3.497C2.26633 3.497 2.418 3.328 2.418 2.99ZM8.463 7.553V6.422C8.463 6.084 8.31133 5.915 8.008 5.915C7.70467 5.915 7.553 6.084 7.553 6.422V7.553C7.553 7.891 7.70467 8.06 8.008 8.06C8.31133 8.06 8.463 7.891 8.463 7.553Z" fill="#1D1D1B"/></svg>
            </div>
            <div v-if="flat.stock && flat.stock != ''" class="flat-info-prices__discount-text">{{ flat.stock }}</div>
        </div>
        <div class="flat-info-sales">
            <div
                v-for="sale in flat.sales"
                :key="sale.id"
                class="flat-info-sales__item" :style="{
                    backgroundColor: sale.bgColor,
                    color: sale.textColor
                }">{{ sale.name }}</div>
        </div>
        <div v-if="flat.oldprice && testAB === 'a-test'" class="visual-flat__old-price">{{ flat.oldprice }} <span class="ruble">a</span></div>
    </div>
</template>

<script>
export default {props: ['flat', 'testAB']};
</script>

<style lang="scss" scoped>
    @import "./FlatInfo";
</style>
