<template>
    <div style="height: 100%">
        <transition name="fade">
            <PageLoading v-if="loadingPage" theme="white"/>
        </transition>

        <transition name="fade">
            <div class="visual-floor" v-if="!loadingPage">

                <Chess ref="chess"
                       :sections="sections"
                       :floors="floors"
                       :buildMap="buildMap"
                       :linkParking="linkParking"
                       :searchUrl="searchUrl"
                       @change="send" />

                <div class="visual-floor__top">
                    <div class="visual-floor__container--mobile">
                        <Breadcrumbs v-if="!isButton" :breadcrumbs="breadcrumbs" :floor="floor.text" />
                        <a v-if="isButton" class="visual-floor__breadcrumbs-button" :href="breadcrumbsPrev">Назад</a>

                        <h1 class="visual-floor__title-el" v-html="pageName"></h1>

                        <div class="visual-floor__chess_button" @click="clickChess">
                            Выбрать квартиру
                        </div>
                    </div>

                    <!-- Экопроект «Светлый» - Секция 8.3 - 19 Этаж -->

                    <!-- <div class="visual-floor__row title__row visual-floor-filter-row">
                        <Filters :filters="filters" :sections="sections" ref="filter" @change="changeFilter" @redirect="send"/>
                        <h1>{{ pageName }}</h1>

                        <div class="visual-floor__chess_button" @click="clickChess">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="10" height="10" stroke="#D9E0E1"/>
                            <rect x="0.5" y="13.5" width="10" height="10" stroke="#D9E0E1"/>
                            <rect x="13.5" y="13.5" width="10" height="10" stroke="#D9E0E1"/>
                          </svg>
                          Выбрать квартиру
                        </div>
                    </div> -->
                </div>

                <div class="visual-floor__main-wrap">
                    <div class="visual-floor__main-container">
                        <div class="visual-floor__main-row">
                            <div class="visual-floor__main" :class="{'no-sections': !isVisisbleFilterSections, 'has-queues': isVisisbleFilterQueues}">
                                <div class="visual-floor__title">
                                    <Filters v-if="isVisisbleFilterSections || isVisisbleFilterQueues" :filters="filters" :sections="sections" :queues="queues" ref="filter" @change="changeFilter" @redirect="send"/>

                                    <h1 v-html="pageName"></h1>

                                    <div class="visual-floor__chess_button" @click="clickChess">
                                        Выбрать квартиру
                                    </div>
                                </div>


                                <div :class="['visual-floor__left', {'is-loading': onLoading}]">

                                  <div class="visual-floor__build-plane">
                                    <BuildPlane :data="buildMap" :sections="sections" @change="send" />
                                  </div>

                                  <Floors v-if="floors.length" :floors="floors" />
                                </div>

                                <div :class="['visual-floor__canvas', {'is-loading': onLoading}]">

                                    <!-- <div v-if="windowViewTop" class="windowView top">
                                      <div class="gallery">
                                        <a v-for="(item, index) in windowViewTop"
                                           :href="item"
                                           :key="index"
                                           data-fancybox="windowViewTop"
                                           data-animation-effect="fade"
                                           data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                                          <div v-if="index == 0" class="innerWrap">
                                            <img src="/visual/images/eye.svg" alt="">
                                            <span class="top">Посмотреть вид из окна</span>
                                          </div>
                                        </a>
                                      </div>
                                    </div> -->
                                    <!-- <div v-if="windowViewBottom" class="windowView bottom">
                                      <div class="gallery">
                                        <a v-for="(item, index) in windowViewBottom"
                                           :href="item"
                                           :key="index"
                                           data-fancybox="windowViewBottom"
                                           data-animation-effect="fade"
                                           data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                                          <div v-if="index == 0" class="innerWrap">
                                            <img src="/visual/images/eye.svg" alt="">
                                            <span class="bottom">Посмотреть вид из окна</span>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    <div v-if="windowViewLeft" class="windowView left">
                                      <div class="gallery">
                                        <a v-for="(item, index) in windowViewLeft"
                                           :href="item"
                                           :key="index"
                                           data-fancybox="windowViewLeft"
                                           data-animation-effect="fade"
                                           data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                                          <div v-if="index == 0" class="innerWrap">
                                            <img src="/visual/images/eye.svg" alt="">
                                            <span class="left">Посмотреть вид из окна</span>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    <div v-if="windowViewRight" class="windowView right">
                                      <div class="gallery">
                                        <a v-for="(item, index) in windowViewRight"
                                           :href="item"
                                           :key="index"
                                           data-fancybox="windowViewRight"
                                           data-animation-effect="fade"
                                           data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                                          <div v-if="index == 0" class="innerWrap">
                                            <img src="/visual/images/eye.svg" alt="">
                                            <span class="right">Посмотреть вид из окна</span>
                                          </div>
                                        </a>
                                      </div>
                                    </div> -->

                                    <CanvasFloor
                                        v-if="viewBox && render"
                                        :viewBox="viewBox"
                                        :svgHeight="svgHeight"
                                        :svgWidth="svgWidth"
                                        :render="render"
                                        :elements="flats"
                                        :entrance="entrance"
                                        :streets="streets"
                                        :windowView="windowView" />
                                </div>

                                <!--
                                <div class="visual-floor__right">
                                    <div class="visual-floor__right-wrap">
                                        <div class="visual-floor__build-plane">
                                            <BuildPlane :data="buildMap" :sections="sections" @change="send" />
                                        </div>

                                        <div class="visual-floor__chess" @click="clickChess">
                                            <div class="visual-floor__chess-link">
                                                <div class="visual-floor__chess-poster">
                                                    <img src="/visual/images/chess-link.svg" alt="">
                                                </div>
                                                <div class="visual-floor__chess-caption">Шахматка</div>
                                            </div>
                                        </div>

                                        <div class="visual-floor__compass"><Compass v-if="compass" :pathImg="compass" /></div>
                                    </div>
                                </div>
                                -->

                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="visual-floor__bottom">
                    <div class="visual-floor__container">
                        <div class="visual-floor__row visual-floor-filter-row">
                            <Filters :filters="filters" :sections="sections" ref="filter" @change="changeFilter" @redirect="send"/>
                        </div>
                    </div>
                </div> -->

            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Filters from '../../components/Filters/Filters';
import Compass from '../../components/Compass/Compass';
import Floors from '../../components/Floors/Floors';
import BuildPlane from '../../components/BuildPlane/BuildPlane';
import Chess from '../../components/Chess/Chess';
import CanvasFloor from '../../components/CanvasFloor/CanvasFloor';
import PageLoading from '../../components/PageLoading/PageLoading';


const defAction = '/ajax/visual.php'; // default

export default {
    components: {
        Filters,
        Breadcrumbs,
        Compass,
        Floors,
        BuildPlane,
        Chess,
        CanvasFloor,
        PageLoading
    },
    props: ['filterData'],
    data : () => {
        return {
            loadingPage: true,
            onLoading  : false,
            breadcrumbs: [],
            breadcrumbsPrev: null,
            filters    : {},
            buildMap   : {},
            sections   : [],
            queues     : [],
            linkParking: '',
            searchUrl: '',
            isVisisbleFilterSections: false,
            isVisisbleFilterQueues: false
        };
    },
    computed: {
        isButton() {
            return true;
        },
        pageName() {
            return `${ this.buildMap.objectName || '' } - ${this.section.name || ''} - ${this.floor.text || ''} Этаж`;
        },

        section() {
            return this.sections ? this.sections.find((section) => {
                return section.active;
            }) : {};
        },
        queue() {
            return this.queue ? this.queues.find((queue) => {
                return queue.active
            }) : {};
        },
        floors() {
            return this.section.floors || [];
        },
        floor() {
            if (!this.floors || !this.floors.length) {
                return null;
            }

            let active = this.floors.find((floor) => {
                return floor.active;
            });

            if (!active) {
                active = this.floors.find((floor) => {
                    return !floor.disabled;
                });

                if (active) {
                    active.active = true;
                }
            }

            return active;
        },
        viewBox() {
            return this.floor ? this.floor.viewBox : '';
        },
        svgHeight() {
            return this.floor ? this.floor.svgHeight : '';
        },
        svgWidth() {
            return this.floor ? this.floor.svgWidth : '';
        },
        render() {
            return this.floor ? this.floor.render : '';
        },
        compass() {
            return this.floor ? this.floor.compass : '';
        },
        flats() {
            return this.floor ? this.floor.flats : [];
        },
        entrance() {
            return this.section && this.section.entrance ? this.section.entrance : false;
        },
        streets() {
            return this.section.streets && this.section.streets ? this.section.streets : [];
        },
        windowView() {
            return this.floor && this.floor.windowView ? this.floor.windowView : [];
        },
        windowViewTop(){
            if (this.floor && this.floor.windowView && this.floor.windowView.IMG_TOP && this.floor.windowView.IMG_TOP.length > 0){
                return this.floor.windowView.IMG_TOP;
            }
            return false;
        },
        windowViewBottom(){
            if (this.floor && this.floor.windowView && this.floor.windowView.IMG_BOTTOM && this.floor.windowView.IMG_BOTTOM.length > 0){
              return this.floor.windowView.IMG_BOTTOM;
            }
            return false;
        },
        windowViewLeft(){
            if (this.floor && this.floor.windowView && this.floor.windowView.IMG_LEFT && this.floor.windowView.IMG_LEFT.length > 0){
              return this.floor.windowView.IMG_LEFT;
            }
            return false;
        },
        windowViewRight(){
            if (this.floor && this.floor.windowView && this.floor.windowView.IMG_RIGHT && this.floor.windowView.IMG_RIGHT.length > 0){
              return this.floor.windowView.IMG_RIGHT;
            }
            return false;
        },
    },

    methods: {
        clickChess() {
            this.$refs.chess.toOpen();
        },

        collectData(formData) {
            formData.append('step', 'floor');
            formData.append('idComplex', this.$appOptions.complexId);
            formData.append('idBuilding', this.$route.params.idBuilding);
            formData.append('idSection', this.$route.params.idSection);
            formData.append('idFloor', this.$route.params.idFloor);
        },

        addAdditionData(additionData, formData) {
            if (!additionData || !(additionData instanceof FormData)) {
                return;
            }

            for (const data of additionData) {
                formData.append(data[0], data[1]);
            }
        },

        changeFilter(formData) {
            this.$emit('saveFilter', formData);

            this.send('filter', formData);
        },

        waitLoadImg(src) {
            return new Promise((resolve, reject) => {
                try {
                    if (!src) {
                        throw new Error('no scr');
                    }

                    const img = new Image();

                    img.onload = resolve;

                    img.src = src;
                } catch (e) {
                    reject(e);
                }
            });
        },

        /**
         * Отправка запроса
         * @param {string} typeReq - Тип запроса (первый запрос или от фильтрации)
         * @param {FormData} additionData - дополнительно отправляемые данные
         */
        send(typeReq = '', additionData = this.filterData) {
            const formData = new FormData();

            this.collectData(formData);
            this.addAdditionData(additionData, formData);
            this.onLoading = true;
            this.countReques++;

            const action = process.env.NODE_ENV === 'production' ? this.$appOptions.url : defAction;

            axios.post(action, formData)
                .then((response) => {
                    this.countReques--;

                    if (this.countReques > 0) {
                        return;
                    }

                    const {data} = response.data;

                    if (!data) {
                        return;
                    }

                    this.breadcrumbs = data.breadcrumbs || [];
                    this.buildMap = data.buildMap;
                    this.linkParking = ( Object.keys(data).includes('linkParking') ) ? data.linkParking : '';
                    this.searchUrl = ( Object.keys(data).includes('searchUrl') ) ? data.searchUrl : '';

                    this.breadcrumbsPrev = ( this.breadcrumbs.length >= 2 ) ? this.breadcrumbs[this.breadcrumbs.length - 1].link : null;

                    this.sections = data.sections;
                    this.queues = data.queues ? data.queues : [];

                    this.isVisisbleFilterSections = data.sections.length > 1;
                    this.isVisisbleFilterQueues = this.queues.length > 0;

                    if (typeReq !== 'filter') {
                        this.filters = data.filters;
                    }

                    this.$emit('saveParametricLink', data.linkParametric || false);
                })
                ['catch']((error) => {
                    this.countReques--;

                    console.error(error);
                })
                .then(() => {
                    if (this.countReques <= 0) {
                        this.countReques = 0;
                        this.onLoading = false;
                    }

                    this.loadingPage = false;
                });
        }
    },
    mounted() {
        this.$nextTick(function () {
            let url = window.location.href;

            if(url.indexOf('?showChess') != -1){
                let chessLink = document.querySelector('.visual-floor__chess_button');
                if (chessLink){
                    chessLink.click();
                }
            }
        });


        this.countReques = 0;

        this.send('', this.filterData);
    },
    updated () {
        let url = window.location.href;

        if(url.indexOf('?showChess') != -1){
            let chessLink = document.querySelector('.visual-floor__chess_button');
            if (chessLink){
                chessLink.click();
            }
        }
    }
};
</script>

<style lang="scss">
    @import "./Floor";
</style>
