<template>
    <div style="height: 100%">

        <transition name="fade">
            <PageLoading v-if="loadingPage"/>
        </transition>

        <transition name="fade">
            <div class="visual-building" v-if="!loadingPage">
                <div class="visual-building__container">
                    <div class="visual-building__row">
                        <div class="visual-building__top">
                            <div style="display: none;">
                                <Breadcrumbs :breadcrumbs="breadcrumbs" />
                            </div>
                            <div class="visual-building__actions">
                                <div class="visual-building__logo">
                                    {{ projectLogoSrc }}
                                    <ProjectLogo :pathImg="objectLogo" />
                                </div>
                                <div class="visual-building__about">
                                    <button class="building-about-button js-open-about">О проекте</button>
                                </div>
                                <div class="visual-building__chess" v-if="chessUrl">
                                    <ChessButton v-if="chessUrl !== false" :link="chessUrl"/>
                                </div>
                                <div class="visual-building__search" v-if="linkParking">
                                    <a :href="linkParking" class="building-about-button building-about-button--parking">Паркинг</a>
                                </div>
                                <!-- <div class="visual-building__search" v-if="linkParkingCallback">
                                    <a href="#" class="j-open-call-back building-about-button building-about-button--parking" data-content-link="call-back">Паркинг</a>
                                </div> -->
                                <div v-if="commerceLink !== false && commerceLink" class="visual-building__search">
                                    <a :href="commerceLink" :target="isExternalLink(commerceLink) ? '_blank' : ''" class="building-search-button">Коммерция</a>
                                </div>
                                <div class="visual-building__search" v-if="linkPantry">
                                    <a :href="linkPantry" class="building-about-button building-about-button--parking">Кладовки</a>
                                </div>
                                <div v-if="isPanorama !== false" class="visual-building__search">
                                    <div class="building-about-button building-about-button--panorama js-open-panorama">Панорама</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="['visual-building__views', {'is-loading': onLoading}]">
                    <Canvas :render="render"
                            :elements="buildings"
                            :viewBox="viewBox"
                            :nextStep="nextStep"
                            :theme="theme"/>
                    <Pointer theme="square" :pointers="signatures" :viewBox="viewBox"/>
                    <Pointer theme="oval" :pointers="pointers" :viewBox="viewBox"/>
                </div>

                <div class="visual-building__compass">
                    <Compass :pathImg="compass"/>
                </div>

                <div class="visual-building__container visual-building-filter-container">
                    <div class="visual-building__row">
                        <div class="visual-building__bottom">
                            <Filters :filters="filters"  ref="filter" @change="changeFilter"/>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';

import Canvas from '../../components/Canvas/Canvas';
import Pointer from '../../components/Pointer/Pointer';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Filters from '../../components/Filters/Filters';
import Compass from '../../components/Compass/Compass';
import PageLoading from '../../components/PageLoading/PageLoading';
import ProjectLogo from '../../components/ProjectLogo/ProjectLogo';
import ChessButton from '../../components/ChessButton/ChessButton';

const defAction = '/ajax/visual.php'; // default

export default {
    components: {
        Canvas,
        Pointer,
        Filters,
        Breadcrumbs,
        Compass,
        PageLoading,
        ProjectLogo,
        ChessButton
    },
    props: ['step', 'nextStep', 'filterData'],
    data : () => {
        return {
            loadingPage: true,
            onLoading  : false,
            breadcrumbs: [],
            viewBox    : '',
            render     : '',
            compass    : '',
            objectLogo : '',
            chessUrl   : '',
            commerceLink: '',
            isPanorama: false,
            searchUrl  : '',
            linkParking: '',
            linkParkingCallback: false,
            linkPantry: '',
            buildings  : [],
            signatures : [],
            pointers   : [],
            filters    : [],
            theme      : {}
        };
    },
    methods: {
        collectData(formData) {
            formData.append('step', this.step);
            formData.append('idComplex', this.$appOptions.complexId);

            if (this.$route.params.idBuilding) {
                formData.append('idBuilding', this.$route.params.idBuilding);
            }

            if (this.$route.params.idSection) {
                formData.append('idSection', this.$route.params.idSection);
            }

            if (this.$route.params.idFloor) {
                formData.append('idFloor', this.$route.params.idFloor);
            }
        },

        addAdditionData(additionData, formData) {
            if (!additionData || !(additionData instanceof FormData)) {
                return;
            }

            for (const data of additionData) {
                formData.append(data[0], data[1]);
            }
        },

        changeFilter(formData) {
            this.$emit('saveFilter', formData);

            this.send('filter', formData);
        },

        waitLoadImg(src) {
            return new Promise((resolve, reject) => {
                try {
                    if (!src) {
                        throw new Error('no scr');
                    }

                    const img = new Image();

                    img.onload = resolve;

                    img.src = src;
                } catch (e) {
                    reject(e);
                }
            });
        },
        isExternalLink(url) {
            const tmp = document.createElement('a');
            tmp.href = url;
            return tmp.host !== window.location.host;
        },
        /**
         * Отправка запроса
         * @param {string} typeReq - Тип запроса (первый запрос или от фильтрации)
         * @param {FormData} additionData - дополнительно отправляемые данные
         */
        send(typeReq = '', additionData = new FormData()) {
            const formData = new FormData();
            const url = window.location.pathname;

            this.collectData(formData);
            this.addAdditionData(additionData, formData);
            this.onLoading = true;
            this.countReques++;

            const action = process.env.NODE_ENV === 'production' ? this.$appOptions.url : defAction;

            axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            axios.post(action, formData)
                .then((response) => {
                    this.countReques--;

                    if (this.countReques > 0) {
                        return;
                    }

                    const {data} = response.data;

                    if (data.redirect) {
                        const urlList = data.redirect.split('/');
                        let newUrl = '';

                        urlList.forEach((elem, i) => {
                            if (elem !== '') {
                                newUrl = `${newUrl + elem}/`;
                            }
                        });

                        window.location.href = this.$appOptions.baseUrl + newUrl;

                        return;
                    }

                    this.breadcrumbs = data.breadcrumbs;
                    this.viewBox = data.viewBox;
                    this.render = data.render;
                    this.compass = data.compass;
                    this.objectLogo = data.objectLogo;
                    this.chessUrl = data.chessUrl;
                    this.commerceLink = data.commerceLink;
                    this.isPanorama = data.isPanorama;
                    this.searchUrl = data.searchUrl;
                    this.linkParking = data.linkParking;
                    this.linkParkingCallback = data.linkParkingCallback;
                    this.linkPantry = data.linkPantry;
                    this.buildings = data.buildings;
                    this.signatures = data.signatures;
                    this.pointers = data.pointers;
                    this.theme = data.theme;

                    if (typeReq !== 'filter') {
                        this.filters = data.filters;
                    }

                    this.$emit('saveParametricLink', data.linkParametric || false);
                })
                ['catch']((error) => {
                    this.countReques--;

                    console.error(error);
                })
                .then(() => {
                    if (this.countReques <= 0) {
                        this.countReques = 0;
                        this.onLoading = false;
                    }

                    this.waitLoadImg(this.render)
                        .then(() => {
                            this.loadingPage = false;

                            setTimeout(function() {
                              let pointerItems = document.querySelectorAll('.visual-pointer__item'),
                                  scrollBlock = document.querySelector('.layout__content .visual'),
                                  screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

                              if (scrollBlock && pointerItems && pointerItems[0]) {

                                let leftScroll = 0;

                                if ( parseInt(pointerItems[0].style.left) > parseInt(screenWidth)/2 ){
                                  leftScroll = parseInt(pointerItems[0].style.left) - (parseInt(screenWidth)/2);
                                }

                                if(leftScroll > 0){
                                  scrollBlock.scrollLeft = leftScroll;
                                }
                              }
                            }, 500);

                        })
                        ['catch'](() => {
                            this.loadingPage = false;
                        });
                });
        }
    },
    mounted() {
        this.countReques = 0;

        this.send('', this.filterData);
    }
};
</script>

<style lang="scss">
    @import "./Complex";
</style>
