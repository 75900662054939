<template>
    <main :class="['visual', {'visual_theme_floor': $route.name === 'floor'}]">
       <router-view :key="$route.name"
                    :filterData="filterData"
                    @saveParametricLink="saveLink"
                    @saveFilter="saveFilter"/>
    </main>
</template>

<style lang='scss'>
    @import "./App.scss";
</style>

<script>
const body = document.body;
const header = document.querySelector('.j-header');
const headerButton = document.querySelector('.j-header-button');

export default {
    data: () => {
        return {
            filterData    : {},
            linkParametric: '/parametric/'
        };
    },
    methods: {
        setHeaderTheme(pageName) {
            if (pageName === 'floor') {
                header.classList.add('header_theme_visual-floor');
                header.classList.remove('header_theme_visual');
                headerButton.classList.remove('button_theme_yellow');
            } else {
                header.classList.add('header_theme_visual');
                headerButton.classList.add('button_theme_yellow');
                header.classList.remove('header_theme_visual-floor');
            }

            //
            // if (pageName === 'complex') {
            //     header.classList.add('header_theme_visual');
            //     headerButton.classList.add('button_theme_yellow');
            //     header.classList.remove('header_theme_visual-floor');
            // } else {
            //     header.classList.add('header_theme_visual-floor');
            //     header.classList.remove('header_theme_visual');
            //     headerButton.classList.remove('button_theme_yellow');
            // }
        },

        saveFilter(formData) {
            this.filterData = formData;
        },

        saveLink(link) {
            this.linkParametric = link;

            this.checkWidthVisual();
        },

        setOverflowBody() {
            body.style.overflow = 'hidden';
        },

        resizeWindow() {
            this.checkWidthVisual(); // Редирект при разрешении менее 1280
        },

        checkWidthVisual() {
            /*
            if (window.innerWidth < 1280 && this.$route.name !== 'error') {
                window.location.href = this.linkParametric || process.env.NODE_ENV === 'production' ? '/' : '/404';
            }
            */
        }
    },
    watch: {
        $route(val) {
            this.setHeaderTheme(val.name);
        }
    },
    mounted() {
        this.setHeaderTheme(this.$route.name);
        this.setOverflowBody();

        window.addEventListener('resize', this.resizeWindow.bind(this), false);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeWindow.bind(this), false);
    }
};
</script>
