<template>
    <div :class="['visual-multi-select', {'visual-multi-select_is_disable': disabled}, {'is-single': single}]"
         v-click-outside="() => {isOpen = false}">
        <div :class="['visual-multi-select__input', {'visual-multi-select__input_is_open':isOpen}]" @click="isOpen = !isOpen">
            <span class="visual-multi-select__input-val">{{ inputText || placeholder }}</span>
            <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L5.5 4.79289L10.1464 0.146447C10.3417 -0.0488155 10.6583 -0.0488155 10.8536 0.146447C11.0488 0.341709 11.0488 0.658291 10.8536 0.853553L5.85355 5.85355C5.65829 6.04882 5.34171 6.04882 5.14645 5.85355L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447Z" fill="#1D1D1B"/>
            </svg>
        </div>

        <div :class="['visual-multi-select__content', {'visual-multi-select__content_is_group': groups}, {'visual-multi-select__content_is_open': isOpen}]">

            <div class="visual-multi-select__item" v-for="(option, key) in options" :key="key">
                <input v-if="!single"
                       type="checkbox"
                       class="visual-multi-select__item-input"
                       :id="option.value"
                       :name="name"
                       :value="option.value"
                       :data-text="option.text"
                       :disabled="option.disabled"
                       v-model="option.checked"
                       @change="changeInput">

                <input v-else
                       type="radio"
                       class="visual-multi-select__item-input"
                       :id="option.value"
                       :name="name"
                       :value="option.value"
                       :data-text="option.text"
                       :disabled="option.disabled"
                       v-model="value">

                <label :for="option.value" class="visual-multi-select__item-label">
                    <span class="visual-multi-select__item-fake">
                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8.5L4.5 12L12 1" stroke="black" stroke-width="2"/></svg>
                    </span>
                    <span class="visual-multi-select__item-text">{{ option.text }}</span>
                </label>
            </div>

            <div class="visual-multi-select__group" v-for="(group, key) in groups" :key="key">
                <div class="visual-multi-select__group-name" @click="clickGroupName(group)">{{ group.text }}</div>

                <div class="multi-select__group-items">

                    <div class="visual-multi-select__item" v-for="(option, key) in group.options" :key="key">
                        <input v-if="!single"
                               type="checkbox"
                               class="visual-multi-select__item-input"
                               :id="option.value"
                               :name="name"
                               :value="option.value"
                               :data-text="option.text"
                               :disabled="option.disabled"
                               v-model="option.checked"
                               @change="changeInput">

                        <input v-else
                               type="radio"
                               class="visual-multi-select__item-input"
                               :id="option.value"
                               :name="name"
                               :value="option.value"
                               :data-text="option.text"
                               :disabled="option.disabled"
                               v-model="value">

                        <label :for="option.value" class="visual-multi-select__item-label">
                            <span class="visual-multi-select__item-fake">
                                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8.5L4.5 12L12 1" stroke="black" stroke-width="2"/></svg>
                            </span>

                            <span class="visual-multi-select__item-text">{{ option.text }}</span>
                        </label>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: ['name', 'options', 'groups', 'placeholder', 'disabled', 'single', 'redirect'],
    data() {
        const selectedItem = this.single && this.options.length > 0 ? this.options.find(item => item.checked) : false;

        return {
            isOpen   : false,
            inputText: selectedItem ? selectedItem.text : '',
            value    : selectedItem ? selectedItem.value : ''
        };
    },
    watch: {
        options() {
            this.changeInput(true, 'update-options');
        },
        value(val) {
            if (this.redirect && this.name !== 'floor') {
                this.$router.push(val);
                this.$emit('redirect');
            }

            if ( this.name !== 'floor' ) this.changeInput(true, 'change-value');
            else {
                if ( this.$router.history.current.path !=  val) location.replace(this.$router.options.base + val.substr(1));
            }
        }
    },
    methods: {
        changeInput(toEmit = true, action = null) {
            let textArray = [];
            const groups = this.groups ? Object.values(this.groups) : [];

            if (this.single) {
                let checkedOption = Object.values(this.options).find((option) => {
                    return option.checked || option.value === this.value;
                });
                const value = checkedOption.value;
                const inputText = checkedOption.text;

                if (this.name === 'queue') {
                    toEmit && this.$emit('updateValue', this.value, action);

                    return;
                }

                this.value = value;
                this.inputText = inputText;

                toEmit && this.$emit('change');

                return;
            }

            if (this.options) {
                const checked = this.options.filter((option) => {
                    return option.checked;
                });

                textArray = checked.map((option) => {
                    return option.text;
                });
            }

            if (groups && groups.length) {
                groups.forEach((group) => {
                    // if (this.allCheckInGroup(group)) {
                    // textArray.push(group.text);
                    // return;
                    // }

                    group.options.forEach((option) => {
                        if (option.checked) {
                            textArray.push(option.text);
                        }
                    });
                });
            }

            this.inputText = textArray.join(', ');

            if (this.name === 'queue') {
                return;
            }

            toEmit && this.$emit('change');
        },
        clickGroupName(group) {
            const allChecked = this.allCheckInGroup(group);

            group.options.forEach((option) => {
                option.checked = !allChecked && !option.disabled;
            });

            this.$nextTick(this.changeInput.bind(this));
        },
        allCheckInGroup(group) {
            return Boolean(!group.options.find((option) => {
                return !option.checked && !option.disabled;
            }));
        },
        setValue() {
            if (!this.single) {
                return;
            }

            this.value = Object.values(this.options).find((option) => {
                return option.checked;
            }).value;
        }
    },
    mounted() {
        this.setValue();
        // this.changeInput(false, 'mounted');
    }
};
</script>

<style lang="scss">
    @import "./MultiSelect";
</style>
