export default function(elements) {
    const result = [];

    Array.from(elements).forEach((item) => {
        const coords = {
            top : `${item.getBoundingClientRect().top + pageYOffset}px`,
            left: `${item.getBoundingClientRect().left + pageXOffset}px`
        };

        result.push(coords);
    });

    return result;
}

