<template>
    <div class="project-logo">
        <img :src="pathImg" class="project-logo__img">
    </div>
</template>

<script>
    export default {props: ['pathImg']};
</script>

<style lang="scss">
    @import "./ProjectLogo";
</style>