<template>
    <ul :class="['visual-breadcrumbs', {'visual-breadcrumbs_is_dark':isFloorPage}, {'visual-breadcrumbs_is_static':isFloorPage}]">
        <li v-for="item in breadcrumbs">
            <component :is="item.disabled ? 'span' : 'a'" :href="item.link">
                <svg width="5" height="9" viewBox="0 0 5 9" fill="white" opacity="0.7" xmlns="http://www.w3.org/2000/svg"><path d="M4.78936 8.88018C4.6296 9.03994 4.37058 9.03994 4.21082 8.88018L0.119908 4.78927C-0.0398516 4.62951 -0.0398516 4.37049 0.119908 4.21073L4.21082 0.11982C4.37058 -0.0399398 4.6296 -0.0399399 4.78936 0.11982C4.94912 0.27958 4.94912 0.538602 4.78936 0.698362L0.987721 4.5L4.78936 8.30164C4.94912 8.4614 4.94912 8.72042 4.78936 8.88018Z"/></svg>
                <span v-html="item.title"></span>
            </component>
        </li>

        <li>
            <component :is="isFloorPage ? 'router-link' : 'span'" :to="{name: 'complex'}">
                <svg width="5" height="9" viewBox="0 0 5 9" fill="white" opacity="0.7" xmlns="http://www.w3.org/2000/svg"><path d="M4.78936 8.88018C4.6296 9.03994 4.37058 9.03994 4.21082 8.88018L0.119908 4.78927C-0.0398516 4.62951 -0.0398516 4.37049 0.119908 4.21073L4.21082 0.11982C4.37058 -0.0399398 4.6296 -0.0399399 4.78936 0.11982C4.94912 0.27958 4.94912 0.538602 4.78936 0.698362L0.987721 4.5L4.78936 8.30164C4.94912 8.4614 4.94912 8.72042 4.78936 8.88018Z"/></svg>
                <span>Визуальный выбор квартиры</span>
            </component>
        </li>

        <li>
            <span v-if="isFloorPage && floor">
                <svg width="5" height="9" viewBox="0 0 5 9" fill="white" opacity="0.7" xmlns="http://www.w3.org/2000/svg"><path d="M4.78936 8.88018C4.6296 9.03994 4.37058 9.03994 4.21082 8.88018L0.119908 4.78927C-0.0398516 4.62951 -0.0398516 4.37049 0.119908 4.21073L4.21082 0.11982C4.37058 -0.0399398 4.6296 -0.0399399 4.78936 0.11982C4.94912 0.27958 4.94912 0.538602 4.78936 0.698362L0.987721 4.5L4.78936 8.30164C4.94912 8.4614 4.94912 8.72042 4.78936 8.88018Z"/></svg>
                <span>Этаж {{ floor }}</span>
            </span>
        </li>
    </ul>
</template>

<script>
export default {
    props   : ['breadcrumbs', 'floor'],
    computed: {
        isFloorPage() {
            return this.$route.name === 'floor';
        }
    }
};
</script>

<style lang="scss">
    @import "./Breadcrumbs.scss";
</style>
