<template>
    <div class="visual-checkbox-line">
        <div v-for="(option, key) in options"
             :key="key"
             class="visual-checkbox-line__item">
            <input type="checkbox"
                   class="visual-checkbox-line__input"
                   v-model="option.checked"
                   :name="name"
                   :value="option.value"
                   :id="`${name}-${option.value}`"
                   :disabled="option.disabled"
                   @change="$emit('change')">

            <label :for="`${name}-${option.value}`" class="visual-checkbox-line__label">
                <span class="visual-checkbox-line__fake">{{ option.text }}</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {props: ['name', 'options']};
</script>

<style lang="scss">
   @import "./CheckboxLine";
</style>
