import Complex from './pages/Complex/Complex.vue';
import Floor from './pages/Floor/Floor.vue';
import PageNotFound from './pages/PageNotFound/PageNotFound.vue';
import Router from 'vue-router';
import Vue from 'vue';

Vue.use(Router);

const el = document.getElementById('visual');
const data = {...el.dataset};

export default new Router({
    mode  : 'history',
    base  : process.env.NODE_ENV === 'production' ? data.baseUrl : '/',
    routes: [{
        path     : '/',
        name     : 'complex',
        component: Complex,
        props    : {
            step    : 'complex',
            nextStep: 'building'
        }
    }, {
        path     : '/building/:idBuilding/',
        name     : 'building',
        component: Complex,
        props    : {
            step    : 'building',
            nextStep: 'section'
        }
    }, {
        path     : '/building/:idBuilding/section/:idSection/',
        name     : 'section',
        component: Complex,
        props    : {
            step    : 'section',
            nextStep: 'floor'
        }
    }, {
        path     : '/building/:idBuilding/section/:idSection/floor/:idFloor',
        name     : 'floor',
        component: Floor
    }, {
        path     : '*',
        name     : 'error',
        component: PageNotFound
    }]
});
