<template>
    <div :class="classes">
        <div class="visual-canvas-floor__container">
            <svg
                class="visual-canvas-floor__svg"
                :viewBox="viewBox"
                :width="svgWidth"
                :height="svgHeight"
                preserveAspectRatio="xMidYMid">

                <image x="0" y="0" width="100%" height="100%" preserveAspectRatio="none" :xlink:href="render" class="visual-canvas-floor__image"/>

                <a v-for="element in elementsPretty"
                :key="element.id"
                :href="element.href"
                v-tooltip.right="{content: loadImage, loadingContent: 'Загрузка...', loadingClass: 'tooltip_is_loading'}"
                @mouseenter="tooltip = element; reverseFill(element)"
                @mouseleave="tooltip = {}; reverseFill(element)">
                    <path class="visual-canvas-floor__path"
                        :class="[
                            'visual-canvas-floor__path',
                            {'visual-canvas-floor__path-disabled': element.reserved}
                        ]"
                        :fill="element.fillColor || 'transparent'"
                        :fill-opacity="element.opacity || 0"
                        :d="element.coords" />
                </a>
            </svg>

            <!-- streets -->

            <div class="visual-canvas-floor__side top" v-if="this.streets.top || (this.windowView.IMG_TOP && this.windowView.IMG_TOP.length > 0)">
                <div class="visual-canvas-floor__side-street" v-if="this.streets.top">
                    <div class="visual-canvas-floor__side-street-icon" :style="streetRotateTop">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_518_6114)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34268 12.2426L4.51425 9.41419L5.22136 8.70709L7.5498 11.0355L7.5498 3.58056L8.5498 3.58057L8.5498 11.0355L10.8782 8.70709L11.5853 9.4142L8.75689 12.2426L8.04978 12.9497L7.34268 12.2426Z" fill="#1D1D1B"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_518_6114">
                                    <rect width="7" height="7" fill="white" transform="translate(13 8) rotate(135)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    {{ this.streets.top.name }}
                </div>
                <a v-for="(item, index) in this.windowView.IMG_TOP"
                    :href="item"
                    :key="index"
                    data-fancybox="windowViewTop"
                    data-animation-effect="fade"
                    data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                    <div v-if="index == 0" class="innerWrap">
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.27271C8.49546 4.27271 7.27275 5.49542 7.27275 6.99997C7.27275 8.50453 8.49546 9.72724 10 9.72724C11.5046 9.72724 12.7273 8.50453 12.7273 6.99997C12.7273 5.49542 11.5045 4.27271 10 4.27271Z" fill="#1D1D1B"/>
                            <path d="M10 0.181763C5.45455 0.181763 1.57274 3.00901 0 6.99995C1.57274 10.9908 5.45455 13.8181 10 13.8181C14.55 13.8181 18.4273 10.9908 20 6.99995C18.4273 3.00901 14.55 0.181763 10 0.181763ZM10 11.5454C7.49093 11.5454 5.45455 9.509 5.45455 6.99991C5.45455 4.49081 7.49093 2.45448 10 2.45448C12.5091 2.45448 14.5455 4.49086 14.5455 6.99995C14.5455 9.50904 12.5091 11.5454 10 11.5454Z" fill="#1D1D1B"/>
                        </svg>
                    </div>
                </a>
            </div>
            <div class="visual-canvas-floor__side bottom" v-if="this.streets.bottom || (this.windowView.IMG_BOTTOM && this.windowView.IMG_BOTTOM.length > 0)">
                <div class="visual-canvas-floor__side-street" v-if="this.streets.bottom">
                    <div class="visual-canvas-floor__side-street-icon" :style="streetRotateBottom">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_518_6114)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34268 12.2426L4.51425 9.41419L5.22136 8.70709L7.5498 11.0355L7.5498 3.58056L8.5498 3.58057L8.5498 11.0355L10.8782 8.70709L11.5853 9.4142L8.75689 12.2426L8.04978 12.9497L7.34268 12.2426Z" fill="#1D1D1B"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_518_6114">
                                    <rect width="7" height="7" fill="white" transform="translate(13 8) rotate(135)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    {{ this.streets.bottom.name }}
                </div>
                <a v-for="(item, index) in this.windowView.IMG_BOTTOM"
                    :href="item"
                    :key="index"
                    data-fancybox="windowViewTop"
                    data-animation-effect="fade"
                    data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                    <div v-if="index == 0" class="innerWrap">
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.27271C8.49546 4.27271 7.27275 5.49542 7.27275 6.99997C7.27275 8.50453 8.49546 9.72724 10 9.72724C11.5046 9.72724 12.7273 8.50453 12.7273 6.99997C12.7273 5.49542 11.5045 4.27271 10 4.27271Z" fill="#1D1D1B"/>
                            <path d="M10 0.181763C5.45455 0.181763 1.57274 3.00901 0 6.99995C1.57274 10.9908 5.45455 13.8181 10 13.8181C14.55 13.8181 18.4273 10.9908 20 6.99995C18.4273 3.00901 14.55 0.181763 10 0.181763ZM10 11.5454C7.49093 11.5454 5.45455 9.509 5.45455 6.99991C5.45455 4.49081 7.49093 2.45448 10 2.45448C12.5091 2.45448 14.5455 4.49086 14.5455 6.99995C14.5455 9.50904 12.5091 11.5454 10 11.5454Z" fill="#1D1D1B"/>
                        </svg>
                    </div>
                </a>
            </div>
            <div class="visual-canvas-floor__side left" v-if="this.streets.left || (this.windowView.IMG_LEFT && this.windowView.IMG_LEFT.length > 0)">
                <div class="visual-canvas-floor__side-street" v-if="this.streets.left">
                    <div class="visual-canvas-floor__side-street-icon" :style="streetRotateLeft">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_518_6114)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34268 12.2426L4.51425 9.41419L5.22136 8.70709L7.5498 11.0355L7.5498 3.58056L8.5498 3.58057L8.5498 11.0355L10.8782 8.70709L11.5853 9.4142L8.75689 12.2426L8.04978 12.9497L7.34268 12.2426Z" fill="#1D1D1B"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_518_6114">
                                    <rect width="7" height="7" fill="white" transform="translate(13 8) rotate(135)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    {{ this.streets.left.name }}
                </div>
                <a v-for="(item, index) in this.windowView.IMG_LEFT"
                    :href="item"
                    :key="index"
                    data-fancybox="windowViewTop"
                    data-animation-effect="fade"
                    data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                    <div v-if="index == 0" class="innerWrap">
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.27271C8.49546 4.27271 7.27275 5.49542 7.27275 6.99997C7.27275 8.50453 8.49546 9.72724 10 9.72724C11.5046 9.72724 12.7273 8.50453 12.7273 6.99997C12.7273 5.49542 11.5045 4.27271 10 4.27271Z" fill="#1D1D1B"/>
                            <path d="M10 0.181763C5.45455 0.181763 1.57274 3.00901 0 6.99995C1.57274 10.9908 5.45455 13.8181 10 13.8181C14.55 13.8181 18.4273 10.9908 20 6.99995C18.4273 3.00901 14.55 0.181763 10 0.181763ZM10 11.5454C7.49093 11.5454 5.45455 9.509 5.45455 6.99991C5.45455 4.49081 7.49093 2.45448 10 2.45448C12.5091 2.45448 14.5455 4.49086 14.5455 6.99995C14.5455 9.50904 12.5091 11.5454 10 11.5454Z" fill="#1D1D1B"/>
                        </svg>
                    </div>
                </a>
            </div>
            <div class="visual-canvas-floor__side right" v-if="this.streets.right || (this.windowView.IMG_RIGHT && this.windowView.IMG_RIGHT.length > 0)">
                <div class="visual-canvas-floor__side-street" v-if="this.streets.right">
                    <div class="visual-canvas-floor__side-street-icon" :style="streetRotateRight">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_518_6114)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34268 12.2426L4.51425 9.41419L5.22136 8.70709L7.5498 11.0355L7.5498 3.58056L8.5498 3.58057L8.5498 11.0355L10.8782 8.70709L11.5853 9.4142L8.75689 12.2426L8.04978 12.9497L7.34268 12.2426Z" fill="#1D1D1B"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_518_6114">
                                    <rect width="7" height="7" fill="white" transform="translate(13 8) rotate(135)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    {{ this.streets.right.name }}
                </div>
                <a v-for="(item, index) in this.windowView.IMG_RIGHT"
                    :href="item"
                    :key="index"
                    data-fancybox="windowViewTop"
                    data-animation-effect="fade"
                    data-options='{"baseClass": "windowView","infobar": false, "buttons": ["close"]}'>
                    <div v-if="index == 0" class="innerWrap">
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4.27271C8.49546 4.27271 7.27275 5.49542 7.27275 6.99997C7.27275 8.50453 8.49546 9.72724 10 9.72724C11.5046 9.72724 12.7273 8.50453 12.7273 6.99997C12.7273 5.49542 11.5045 4.27271 10 4.27271Z" fill="#1D1D1B"/>
                            <path d="M10 0.181763C5.45455 0.181763 1.57274 3.00901 0 6.99995C1.57274 10.9908 5.45455 13.8181 10 13.8181C14.55 13.8181 18.4273 10.9908 20 6.99995C18.4273 3.00901 14.55 0.181763 10 0.181763ZM10 11.5454C7.49093 11.5454 5.45455 9.509 5.45455 6.99991C5.45455 4.49081 7.49093 2.45448 10 2.45448C12.5091 2.45448 14.5455 4.49086 14.5455 6.99995C14.5455 9.50904 12.5091 11.5454 10 11.5454Z" fill="#1D1D1B"/>
                        </svg>
                    </div>
                </a>
            </div>

            <div v-if="entrance" class="visual-canvas-floor__entrance">Вход в подъезд <div class="visual-canvas-floor__entrance-arrow" :style=style></div></div>
        </div>

        <Tooltip theme="floor" :tooltip="tooltip" :testAB="testAB" v-show="false" ref="foo" />
    </div>
</template>

<script>
import Tooltip from '../Tooltip/Tooltip';

export default {
    components: {Tooltip},
    props     : [
        'viewBox', 'render',
        'elements', 'svgHeight',
        'svgWidth', 'entrance',
        'streets', 'windowView'
    ],
    data      : () => {
        return {
            tooltip     : {},
            tooltipHtml : ' ',
            hoverElement: {},
            testAB      : false

        };
    },
    watch: {
        tooltip(tooltip) {
            if (!tooltip) {
                this.tooltipHtml = '';

                return;
            }

            setTimeout(() => {
                if (!this.$refs || !this.$refs.foo || !this.$refs.foo.$el) {
                    this.tooltipHtml = '';

                    return;
                }

                this.tooltipHtml = this.$refs.foo.$el.innerHTML;
            }, 0);
        }
    },
    computed: {
        elementsPretty() {
            return this.elements.filter((element) => {
                return (!element.sold && element.checked) || (element.sold && element.reserved);
            });
        },
        style() {
            return { transform: `rotate(${this.entrance.rotate}deg)` };
        },
        streetRotateTop() {
            return { transform: `rotate(${this.streets && this.streets.top && this.streets.top.rotate ? this.streets.top.rotate : 0}deg)` };
        },
        streetRotateBottom() {
            return { transform: `rotate(${this.streets && this.streets.bottom && this.streets.bottom.rotate ? this.streets.bottom.rotate : 0}deg)` };
        },
        streetRotateLeft() {
            return { transform: `rotate(${this.streets && this.streets.left && this.streets.left.rotate ? this.streets.left.rotate : 0}deg)` };
        },
        streetRotateRight() {
            return { transform: `rotate(${this.streets && this.streets.right && this.streets.right.rotate ? this.streets.right.rotate : 0}deg)` };
        },
        classes() {
            const classes = ['visual-canvas-floor'];

            if (this.entrance) {
                classes.push('entrance-' + this.entrance.side)
            }

            let streetSides = Object.keys(this.streets).map((item) => `${item}s`).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
            if (this.windowView) {
                const windowViewKeys = Object.keys(this.windowView);

                if (windowViewKeys.includes('IMG_TOP') && !streetSides.includes('tops')) {
                    streetSides.push('tops');
                }

                if (windowViewKeys.includes('IMG_LEFT') && !streetSides.includes('lefts')) {
                    streetSides.push('lefts')
                }

                if (windowViewKeys.includes('IMG_RIGHT') && !streetSides.includes('rights')) {
                    streetSides.push('rights')
                }

                if (windowViewKeys.includes('IMG_BOTTOM') && !streetSides.includes('bottoms')) {
                    streetSides.push('bottom')
                }
            }

            if (streetSides.length === 4) {
                classes.push('street-all');
            } else if (streetSides.length > 0) {
                classes.push(`street-${streetSides.join('-')}`);
            } else {
                classes.push(`street-none`);
            }


            return classes.join(' ');
        }
    },
    beforeMount() {
        this.testAB = this.getCookie('AB_TEST_CLASS');
    },
    methods: {
        loadImage() {
            if (!this.tooltip.img) {
                return false;
            }

            return this.waitLoadImg(this.tooltip.img).then(() => {
                return this.tooltipHtml;
            });
        },
        // возвращает куки с указанным name,
        // или undefined, если ничего не найдено
        getCookie(name) {
        // eslint-disable-next-line max-len,no-useless-escape
            const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));

            // eslint-disable-next-line no-undefined
            return matches ? decodeURIComponent(matches[1]) : undefined;
        },
        reverseFill(element) {
            const fill = element.fillColor;
            const opacity = element.opacity;

            if (element.fillColorHover) {
                element.fillColor = element.fillColorHover;
                element.fillColorHover = fill;
            }

            if (element.opacityHover) {
                element.opacity = element.opacityHover;
                element.opacityHover = opacity;
            }
        },

        waitLoadImg(src) {
            return new Promise((resolve, reject) => {
                try {
                    if (!src) {
                        throw new Error('no scr');
                    }

                    const img = new Image();

                    img.onload = resolve;

                    img.src = src;
                } catch (e) {
                    reject(e);
                }
            });
        }
    }
};
</script>

<style lang='scss'>
    @import "./CanvasFloor";
</style>
