import 'formdata-polyfill';
import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const url = '/ajax/visual.php';
const defRotate = '1';

export default new Vuex.Store({
    state: {
        data: {
            render     : '',
            elements   : '',
            pointers   : '',
            floors     : '',
            breadcrumbs: '',
            views      : [],
            compass    : ''
        },
        canvasSize: {
            width : 0,
            height: 0
        },
        filters: {}
    },
    actions: {
        send(context, data) {

        }
    }
});
