<template>
    <div class='visual-pointer'>
        <svg class="visual-pointer__svg-canvas" :viewBox="viewBox ? viewBox : '0 0 1920 1080'" preserveAspectRatio="xMidYMid slice" >
            <template v-for="pointer in pointers">
                <svg
                    :x="[pointer.left ? pointer.left : 0]"
                    :y="[pointer.top ? pointer.top : 0]"
                    :key=pointer.id
                    ref="anchor"/>
            </template>
        </svg>

        <div v-for="pointer in pointers" :key="pointer.id">
            <div v-if="theme === 'square'">
                <div v-if="pointer.reposition.top"
                    :class="['visual-pointer__item']"
                    :key="pointer.id"
                    :style="{ top: pointer.reposition.top, left: pointer.reposition.left, backgroundColor: pointer.background, borderColor: pointer.background}"
                    ref="pointers"
                >{{pointer.text}}</div>
            </div>

            <div v-else-if="theme === 'oval'">
                <div v-if="pointer.reposition.top"
                     :class="['visual-pointer__item-oval']"
                     :key="pointer.id"
                     :style="{ top: pointer.reposition.top, left: pointer.reposition.left}"
                     ref="pointers">
                    <span class="visual-pointer__arrow" :style="{transform: `rotate(${pointer.arrowRotate}deg)`}"><svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.00005 1V5H6.00005V1.70708L0.728591 6.97854L0.0214844 6.27143L5.29292 1H2.00006V0H6.00005H7.00005H7.00006V1H7.00005ZM6.00005 1H6.00001L6.00005 1.00005V1Z" fill="#1D1D1B"/></svg></span>
                    {{pointer.text}}
                    <span class="visual-pointer__caption">{{ pointer.caption }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang='scss'>
    @import "./Pointer.scss";
</style>

<script>
import reposition from '../../helpers/reposition';

export default {
    props: ['theme', 'pointers', 'viewBox'],

    methods: {
        /**
         * Метод позиционирует поинтеры согласно координатам svg-якорей.
         */
        position() {
            if (this.pointers && this.pointers.length) {
                const coords = reposition(this.$refs.anchor);

                this.pointers.forEach((pointer, key) => {
                    pointer.reposition = coords[key];
                });
            }
        }
    },

    watch: {
        pointers() {
            setTimeout(() => {
                this.position();
            }, 0);
        }
    },

    mounted() {
        this.position();

        window.addEventListener('resize', this.position);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.position);
    }
};
</script>
