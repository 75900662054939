<template>
    <form :class="['visual-filters', {'visual-filters_theme_floor': $route.name === 'floor'}]"
          ref="form">

        <!--
        <div v-for="(filter, key) in filters"
             :key="key"
             :class="['visual-filters__item', {'visual-filters__item_is_select': filter.type === 'multi-select'}]">
            <CheckboxLine v-if="filter.type === 'checkbox'"
                          :name="filter.name"
                          :options="filter.options"
                          @change="change"/>

            <MultiSelect v-else-if="filter.type === 'multi-select'"
                         :key="filter.name"
                         :name="filter.name"
                         :single="false"
                         :options="filter.options"
                         :groups="filter.groups"
                         :placeholder="filter.placeholder"
                         :disabled="filter.disabled"
                         @change="change"/>
        </div>
        -->
        <div class="visual-filters__item visual-filters__item_is_select">
            <MultiSelect v-if="this.hasOwnProperty(queues) && Object.values(this.queues).length > 0"
                key="queue"
                name="queue"
                :single="true"
                :redirect="false"
                :disabled="false"
                :placeholder="'Очередь'"
                :options="queuesSelect"
                @change="change"
                @updateValue="updateValue"
                />
        </div>
        <div class="visual-filters__item visual-filters__item_is_select">
            <MultiSelect v-if="sectionsSelect"
                key="section"
                name="section"
                :single="true"
                :redirect="true"
                :disabled="false"
                :placeholder="'Дом'"
                :options="sectionsSelect"
                @redirect="$emit('redirect')"/>
        </div>
    </form>
</template>

<script>
import CheckboxLine from '../forms/CheckboxLine/CheckboxLine';
import MultiSelect from '../forms/MultiSelect/MultiSelect';

export default {
    components: {
        CheckboxLine,
        MultiSelect
    },
    props   : ['filters', 'sections', 'queues'],
    computed: {
        sectionsSelect() {
            if (!this.sections) {
                return false;
            }

            return this.sections.map((section) => {
                return {
                    checked : section.active,
                    disabled: section.disabled,
                    text    : section.name,
                    value   : section.redirect || ''
                };
            });
        },
        queuesSelect() {
            if (!this.queues) {
                return false;
            }

            return Object.values(this.queues).map((queue) => {
                return {
                    checked : queue.active,
                    disabled: queue.disabled,
                    text    : queue.name,
                    value   : queue.redirect || ''
                };
            });
        }
    },
    methods: {
        updateValue(value, action) {
            if (action === 'change-value') {
                if (this.$router.history.current.path !=  value) location.replace(this.$router.options.base + value.substr(1));
            }
        },
        change() {
            const formData = new FormData(this.$refs.form);

            // this.$emit('change', formData);
        },
    }
};
</script>

<style lang="scss">
    @import "./Filters.scss";
</style>
