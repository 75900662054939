/* import 'common/scripts/polyfills'; */
import App from './App.vue';
import router from './router';
import store from './store';
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import {VueHammer} from 'vue2-hammer';
import VTooltip from 'v-tooltip';
import PerfectScrollbar from 'vue2-perfect-scrollbar';

const el = document.getElementById('visual');

Vue.prototype.$appOptions = {...el.dataset};
Vue.config.productionTip = false;
Vue.use(PerfectScrollbar);
Vue.use(vClickOutside);
Vue.use(VueHammer);
Vue.use(VTooltip);


new Vue({
    router,
    store,
    render: (h) => {
        return h(App);
    }
}).$mount('#visual');
