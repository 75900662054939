<template>
    <div class="visual-compass">
        <img :src="pathImg" alt="">
    </div>
</template>

<script>
export default {props: ['pathImg']};
</script>

<style lang="scss">
    .visual-compass {
        pointer-events: none;
        user-select: none;
    }
</style>
