<template>
    <a :href="link" class="button-chess">Выбрать квартиру</a>
</template>

<script>
export default { props: ["link"] };
</script>

<style lang="scss">
    @import "./ChessButton";
</style>
