<template>
    <div v-if="tooltip">
        <div v-if="theme === 'build'" class="visual-build-tooltip" >
            <div class="visual-build-tooltip__title">{{ tooltip.title }}</div>
            <div class="visual-build-tooltip__type" v-if="tooltip.type">{{ tooltip.type }}</div>

            <div :class="{'visual-build-tooltip__props': true, 'no-flats': !tooltip.hasOwnProperty('flats') || Object.keys(tooltip.flats).length <= 0}">
                <div class="visual-build-tooltip__props-item" v-if="tooltip.term">
                    <div class="visual-build-tooltip__props-label">{{ tooltip.term.label }}</div>
                    <div class="visual-build-tooltip__props-value">{{ tooltip.term.value }}</div>
                </div>

                <div :class="['visual-build-tooltip__props-item', {'value-list': tooltip.decoration.type == 'list'}]" v-if="tooltip.decoration">
                    <div class="visual-build-tooltip__props-label">{{ tooltip.decoration.label }}</div>

                    <div class="visual-build-tooltip__props-value string" v-if="tooltip.decoration.type == 'string'" v-html="tooltip.decoration.value"></div>

                    <div class="visual-build-tooltip__props-value list" v-else-if="tooltip.decoration.type == 'list'">
                        <div v-for="(decoration, key) in tooltip.decoration.value" :key="key" class="visual-build-tooltip__props-decoration">
                            <div class="visual-build-tooltip__props-decorsection">{{ decoration.name }}</div>
                            <div class="visual-build-tooltip__props-decorvalue">{{ decoration.value }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="['visual-build-tooltip__flats', 'visual-build-tooltip__props']">
                <div v-for="(flat, key) in tooltip.flats" :key="key" :class="['visual-build-tooltip__props-item', {'visual-build-tooltip__flats_is_disabled': flat.disabled}]">
                    <div class="visual-build-tooltip__props-label">{{ flat.name }} <span>{{ flat.count }} шт.</span></div>
                    <div class="visual-build-tooltip__props-value price"><span>{{ flat.priceFrom }}</span></div>
                </div>

                <!-- <span>{{ flat.name }}</span>
                <span>{{ flat.count }}</span>
                <span>{{ flat.priceFrom }}</span> -->
            </div>
        </div>

        <div v-else-if="theme === 'floor'" class="visual-floor-tooltip">
            <div class="visual-floor-tooltip__img"><img :src="tooltip.img" alt=""></div>
            <div class="visual-floor-tooltip__title">{{ tooltip.title }}</div>
            <div class="visual-floor-tooltip__info">
                <span v-if="tooltip.reserved">Забронирована</span>
                <span v-else-if="tooltip.oldprice && testAB === 'b-test'">{{tooltip.oldprice}}  <span class="ruble">i</span></span>
                <span v-else>{{tooltip.price}}  <span class="ruble">i</span></span>
                <span>{{ tooltip.area }}</span>
            </div>
            <div v-if="tooltip.oldprice && testAB === 'a-test'" class="visual-floor-tooltip__subinfo">
                <span class="visual-floor-tooltip__old-price">{{ tooltip.oldprice }} <span class="ruble">i</span></span>
            </div>
            <div v-if="tooltip.oldprice && testAB === 'b-test'" class="visual-floor-tooltip__info-discount">
                <div class="visual-floor-tooltip__info-discount-icon">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.971 7.605C9.971 8.18567 9.78033 8.632 9.399 8.944C9.02633 9.256 8.56267 9.412 8.008 9.412C7.45333 9.412 6.98533 9.256 6.604 8.944C6.23133 8.632 6.045 8.18567 6.045 7.605V6.37C6.045 5.78933 6.23133 5.343 6.604 5.031C6.98533 4.719 7.45333 4.563 8.008 4.563C8.56267 4.563 9.02633 4.719 9.399 5.031C9.78033 5.343 9.971 5.78933 9.971 6.37V7.605ZM3.926 3.042C3.926 3.62267 3.73533 4.069 3.354 4.381C2.98133 4.693 2.51767 4.849 1.963 4.849C1.40833 4.849 0.940333 4.693 0.559 4.381C0.186333 4.069 0 3.62267 0 3.042V1.807C0 1.22633 0.186333 0.78 0.559 0.468C0.940333 0.156 1.40833 0 1.963 0C2.51767 0 2.98133 0.156 3.354 0.468C3.73533 0.78 3.926 1.22633 3.926 1.807V3.042ZM8.008 0.0780005L3.601 9.334H1.963L6.37 0.0780005H8.008ZM2.418 2.99V1.859C2.418 1.521 2.26633 1.352 1.963 1.352C1.65967 1.352 1.508 1.521 1.508 1.859V2.99C1.508 3.328 1.65967 3.497 1.963 3.497C2.26633 3.497 2.418 3.328 2.418 2.99ZM8.463 7.553V6.422C8.463 6.084 8.31133 5.915 8.008 5.915C7.70467 5.915 7.553 6.084 7.553 6.422V7.553C7.553 7.891 7.70467 8.06 8.008 8.06C8.31133 8.06 8.463 7.891 8.463 7.553Z" fill="#1D1D1B"/></svg>
                </div>
                <div class="visual-floor-tooltip__info-discount-text">
                    Скидка при 100% оплате. Подробности уточняйте в отделе продаж.
                </div>
            </div>
            <div v-if="tooltip.utp" class="visual-floor-tooltip__upt">
                <span v-for="key in 2" :key="key">{{ tooltip.utp[key-1] }}</span>
                <span v-if="tooltip.hasOwnProperty('utp') && tooltip.utp.length > 2" class="visual-floor-tooltip__upt-more"><span></span><span></span><span></span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {props: ['theme', 'tooltip', 'testAB']};
</script>

<style lang="scss">
    @import "./Tooltip";
</style>
